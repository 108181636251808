import React from 'react'
import ConsultForm from '../components/ConsultationForm/Form'

const Consultation = () => {
  return (
    <>
      <ConsultForm />
    </>
  )
}

export default Consultation