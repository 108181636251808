import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { MainContainer, TitleContainer, Title, EventsContainer, EventCard, EventDetails, EventTitle, EventDates, EventLocation, EventPicture} from './EventsElements';

const Events = () => {
    const [events, setEvents] = useState([])

    const getEvents = async () => {
        const response = await axios.get('events.json')
        setEvents(response.data)
        console.log('test')
    }
  
    useEffect(() => {
        getEvents();
    }, [])
  
    return (
      <>
          <MainContainer>
              <TitleContainer>
                  <Title>Upcoming Events</Title>
              </TitleContainer>
              <EventsContainer>
              {events.map((events) => (
                <EventCard key={events.id}>
                  <a href={events.url}>
                    <EventPicture src={events.image} />
                  </a>
                  <EventTitle>{events.title}</EventTitle>
                  <EventDates>{events.start_date}</EventDates>
                  <EventDates>{events.end_date}</EventDates>
                  <EventLocation>{events.location}</EventLocation>
                </EventCard>
              ))}
              </EventsContainer>
          </MainContainer>
      </>
    )
  }

export default Events