import React from 'react'
import Bookings from '../../components/Artist/Bookings/Bookings'
import Navbar from '../../components/Artist/Navbar/Navbar'

const BookingsPage = () => {
  return (
    <>
        <Navbar />
        <Bookings />
    </>
  )
}

export default BookingsPage