import React from 'react'
import Messages from '../../components/Artist/Messages/Messages'
import Navbar from '../../components/Artist/Navbar/Navbar'

const MessagesPage = () => {
  return (
    <>
      <Navbar />
      <Messages />
    </>
  )
}

export default MessagesPage