import React from 'react'
import Calendar from '../../components/Artist/Calendar/Calendar'
import Navbar from '../../components/Artist/Navbar/Navbar'

const CalendarPage = () => {
  return (
    <>
      <Navbar />
      <Calendar />
    </>
  )
}

export default CalendarPage