import React from 'react'
import Login from '../../components/Artist/Login/Login'

const ArtistLoginPage = () => {
  return (
    <>
      <Login />
    </>
  )
}

export default ArtistLoginPage