import React from 'react'
import Navbar from '../../components/Artist/Navbar/Navbar';
import Profile from '../../components/Artist/Profile/Profile';

const ProfilePage = () => {
  return (
    <>
        <Navbar />
        <Profile />
    </>
  )
}

export default ProfilePage