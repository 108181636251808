import React from 'react'
import Merch from '../components/Merch/Merch'

const MerchPage = () => {
  return (
    <>
      <Merch />
    </>
  )
}

export default MerchPage