import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
    max-w-screen-xl
    mx-auto
    p-8
    lg:h-160
`

export const StyledSection = tw.section`
    text-gray-700
`

export const ContainerDiv = tw.div`
    container
    px-5
    mx-auto
    // bg-slate-100/25
    py-6
    rounded-xl
    dark:bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20
`

export const StyledDiv = tw.div`
    text-center
    mb-4
`

export const Title = tw.h1`
    sm:text-3xl
    text-2xl
    font-medium
    text-center
    text-white
    mb-4
    font-acme
`

export const StyledP = tw.p`
    text-base
    leading-relaxed
    xl:w-2/4
    lg:w-3/4
    mx-auto
    text-white
    font-acme
`
export const OuterDiv = tw.div`
    flex
    flex-wrap
    lg:w-4/5
    sm:mx-auto
    sm:mb-2
    -mx-2
`

export const InnerDiv = tw.div`
    w-full
    lg:w-1/2
    px-4
    py-2
`

export const StyledDetails = tw.details`
    text-xl
    mb-4
    font-acme
`

export const StyledSummary = tw.summary`
    font-semibold
    bg-gray-200
    rounded-md
    py-2
    px-4
    bg-gradient-to-r from-yellow-400/50 via-yellow-400/50 to-yellow-400/50
    font-acme
`

export const StyledSpan = tw.span`
    text-white
    font-acme
`