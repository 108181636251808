import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MainContainer, ContentContainer, ArtistPictureContainer, ArtistPicture, ArtistBioContainer, ArtistBio, StyledButton } from './MiniAboutElements';
import image from '../../assets/images/stephaniejohnson.png';

const MiniAbout = () => {
  const [artistInfo, setArtistInfo] = useState([]);

  const getArtistInfo = async () => {
        const response = await axios.get('artistInfo.json')
        setArtistInfo(response.data)
    }
  
  useEffect(() => {
      getArtistInfo();
  }, [])

  return (
    <>
      <MainContainer>
        <div key={artistInfo.id}>
        {artistInfo.map((artistInfo) => (
          <ContentContainer key={artistInfo.id}>
          <ArtistPicture src={artistInfo.image} />
            <ArtistBio>{artistInfo.bio}</ArtistBio>
            <StyledButton type="button"><a href="/about">Read Bio</a></StyledButton>
        </ContentContainer>
        ))}
        </div>
      </MainContainer>
    </>
  )
}

export default MiniAbout