import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import { MainContainer, StyledForm, FormDiv, FormHeading, StyledInput, RelativeDiv, StyledOuterDiv, StyledDualInputDiv, StyledLabel, StyledInputDiv, StyledTextAreaLabel, StyledTextArea, StyledButton } from './FormElements';
import send from 'emailjs-com';
import emailjs from 'emailjs-com';
import axios from 'axios';

const ConsultForm = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber ] = useState("")
  const [tattooSize, setTattooSize ] = useState("")
  const [tattooPlacement, setTattooPlacement ] = useState("")
  const [tattooConcept, setTattooConcept ] = useState("")
  const [imageRefrence, setImageRefrence] = useState("")
  const [existingTattoo, setExistingTattoo] = useState("")
  const [existingTattooImage, setExistingTattooImage] = useState("")
  const [preferredAppointmentDays, setPreferredAppointmentDays] = useState("")
  const [additionalComments, setAdditionalComments] = useState("")


  const sendConsultationForm = async (e) => {
    let formField = new FormData()
    formField.append('First Name', firstName)
    formField.append('Last Name', lastName)
    formField.append('Email', email)
    formField.append('Phone Number', phoneNumber)
    formField.append('Tattoo Size', tattooSize)
    formField.append('Tattoo Placement', tattooPlacement)
    formField.append('Tattoo Concept', tattooConcept)
    formField.append('Existing Tattoos To Workaround', existingTattoo)
    formField.append('Preferred Appointment Days', preferredAppointmentDays)
    formField.append('Additional Comments', additionalComments)
    
    send.send('service_4gtm88t', 'template_cn9vacv', formField, 'r8DSkThdCzsJPjpTT')
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
};

const form = document.getElementById('consultation-form');

const handleSubmit = (e) => {
  e.preventDefault();
  
  emailjs.sendForm('service_4gtm88t', 'template_cn9vacv', form, 'r8DSkThdCzsJPjpTT')
    .then((result) => {
      console.log('Email sent successfully:', result.text);
      alert("Thank you for your submission, we will get back to you ASAP!")
    }, (error) => {
      console.error('Error sending email:', error);
    });
}
  
  return (
    <>
      <MainContainer>
        <StyledForm id="consultation-form">
          <FormDiv onSubmit={handleSubmit}>
            <FormHeading>Consultation Form</FormHeading>
            <StyledOuterDiv>
              <StyledDualInputDiv>
                <RelativeDiv>
                  <StyledLabel>First Name</StyledLabel>
                  <StyledInput type="text" id="first" name="first" placeholder="First Name" defaultValue={firstName}  onChange={(e) => setFirstName(e.target.value)}/>
                </RelativeDiv>
              </StyledDualInputDiv>
              <StyledDualInputDiv>
                <RelativeDiv>
                <StyledLabel>Last Name</StyledLabel>
                <StyledInput type="text" id="last" name="last" placeholder="Last Name" defaultValue={lastName}  onChange={(e) => setLastName(e.target.value)}/>
                </RelativeDiv>
              </StyledDualInputDiv>
              <StyledInputDiv>
              <StyledLabel>Email</StyledLabel>
                <StyledInput type="email" id="email" name="email" placeholder="whatisyouremail@email.com" defaultValue={email}  onChange={(e) => setEmail(e.target.value)}/>
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledLabel>Phone Number</StyledLabel>
                <StyledInput type="number" id="phone_number"  name="phone" placeholder="Phone Number" defaultValue={phoneNumber}  onChange={(e) => setPhoneNumber(e.target.value)}/>
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledTextAreaLabel>
                  <StyledLabel>Tattoo Size?</StyledLabel>
                  <StyledTextArea id="tattoo_size" name="size" placeholder="How big do you want the tattoo?" defaultValue={tattooSize}  onChange={(e) => setTattooSize(e.target.value)}></StyledTextArea>
                </StyledTextAreaLabel>
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledTextAreaLabel>
                <StyledLabel>Tattoo Placement</StyledLabel>
                  <StyledTextArea id="tattoo_placement" name="placement" placeholder="Detail where you want the tattoo placed" defaultValue={tattooPlacement}  onChange={(e) => setTattooPlacement(e.target.value)}></StyledTextArea>
                </StyledTextAreaLabel>
              </StyledInputDiv>
              <StyledInputDiv>
              <StyledLabel>Tattoo Concept</StyledLabel>
                  <StyledTextArea id="tattoo_concept" name="concept" placeholder="Explain your tattoo concept if you would like to" defaultValue={tattooConcept}  onChange={(e) => setTattooConcept(e.target.value)}></StyledTextArea>
              </StyledInputDiv>
              {/* <StyledInputDiv>
                <StyledLabel>Image Reference</StyledLabel>
                <StyledInput type="file" id="image_reference" name="image_reference" defaultValue={imageRefrence}  onChange={(e) => setImageRefrence(e.target.value)}/>
              </StyledInputDiv> */}
              <StyledInputDiv>
                  <StyledLabel>Existing Tattoos to Workaround</StyledLabel>
                  <StyledTextArea id="existing_tattoos" name="existing" placeholder="Are there any existing tattoos that we will need to workaround? Please explain the area if so" defaultValue={existingTattoo}  onChange={(e) => setExistingTattoo(e.target.value)}></StyledTextArea>
              </StyledInputDiv>
              {/* <StyledInputDiv>
                <StyledLabel>Existing Tattoos to Workaround Image:</StyledLabel>
                <StyledInput type="file" id="existing-tattoo-refrence" name="existing-tattoo-refrence" defaultValue={existingTattooImage}  onChange={(e) => setExistingTattooImage(e.target.value)}/>
              </StyledInputDiv> */}
              <StyledInputDiv>
                  <StyledLabel>Preferred Appointment Days:</StyledLabel>
                  <StyledInput type="text" id="preferred_appointment_days" name="days" placeholder="Mon" defaultValue={preferredAppointmentDays}  onChange={(e) => setPreferredAppointmentDays(e.target.value)}></StyledInput>
              </StyledInputDiv>
              <StyledInputDiv>
                  <StyledLabel>Additional Comments:</StyledLabel>
                  <StyledTextArea id="additional_comments" name="comments" placeholder="Please enter any other relavent information" defaultValue={additionalComments}  onChange={(e) => setAdditionalComments(e.target.value)}></StyledTextArea>
              </StyledInputDiv>
              <StyledButton type="submit" onClick={handleSubmit}>Submit</StyledButton>
            </StyledOuterDiv>
          </FormDiv>
        </StyledForm>
      </MainContainer>
    </>
  )
}

export default ConsultForm