import React, { useState, useEffect } from 'react'
import { MainContainer, TitleContainer, Title, ItemsContainer, ItemCard, ItemDetails, ItemPicture, ItemName, ItemDesc, ItemPrice} from './MerchElements';
import axios from 'axios';


const Merch = () => {
    const [merchItems, setMerchItems] = useState([])

    const getMerchItems = async () => {
        const response = await axios.get('merchItems.json')
        setMerchItems(response.data)
        console.log('test')
    }
  
    useEffect(() => {
        getMerchItems();
    }, [])
  
    return (
      <>
          <MainContainer>
              <ItemsContainer>
              {merchItems.map((merchItems) => (
                <ItemCard key={merchItems.id}>
                  <ItemName>{merchItems.name}</ItemName>
                  <ItemPicture src={merchItems.image} />
                  <ItemDesc>{merchItems.desc}</ItemDesc>
                  <ItemPrice>${merchItems.price}</ItemPrice>
                </ItemCard>
              ))}
              </ItemsContainer>
          </MainContainer>
      </>
    )
  }

export default Merch