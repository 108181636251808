import React from 'react'
import Events from '../components/Events/Events';

const EventsPage = () => {
  return (
    <>
      <Events />
    </>
  )
}

export default EventsPage