import styled from 'styled-components'
import tw from 'tailwind-styled-components';

export const PostContainer = tw.div`
container
px-2
mx-auto
bg-slate-100/25
py-2
rounded-xl
`

export const PostImage = tw.img`
    w-250
    rounded-lg w-full
    outline-white
    outline-offset-2 outline-4
`