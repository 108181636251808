import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
    mx-4
`

export const TitleContainer = tw.div`
    container
    mx-auto    
`

export const Title = tw.h2`
    text-2xl
    text-white
    font-acme
`

export const GalleryContainer = tw.div`
justify-center
align-center
flex
flex-wrap
overflow-auto
whitespace-nowrap
`

export const StyledImage = tw.img`

`

export const GalleryCard = tw.div`
    p-4
    my-2
    mx-2
    bg-white
    rounded-lg
    shadow
    dark:bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20
`

export const GalleryPicture = tw.img`
    rounded-lg
    object-cover
    w-64
    h-64
`