import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MainContainer, ContentContainer, ArtistPictureContainer, ArtistPicture, ArtistBioContainer, ArtistBio } from '../About/AboutElements'

const About = () => {
  const [artistInfo, setArtistInfo] = useState([]);

  const getArtistInfo = async () => {
        const response = await axios.get('artistInfo.json')
        setArtistInfo(response.data)
        console.log(response.data)
    }
  
  useEffect(() => {
      getArtistInfo();
  }, [])

  return (
    <>
        <MainContainer>
        <div key={artistInfo.id}>
        {artistInfo.map((artistInfo) => (
          <ContentContainer>
          {/* <ArtistPictureContainer>
            <ArtistPicture src={artistInfo.image} />
          </ArtistPictureContainer> */}
          <ArtistBioContainer>
            <ArtistBio >{artistInfo.sectionOne}</ArtistBio>
            <br />
            <ArtistBio >{artistInfo.sectionTwo}</ArtistBio>
            <br />
            <ArtistBio >{artistInfo.sectionThree}</ArtistBio>
            <br />
            <ArtistBio >{artistInfo.sectionFour}</ArtistBio>
            <br />
            <ArtistBio >{artistInfo.sectionFive}</ArtistBio>
            <br />
            <ArtistBio >{artistInfo.sectionSix}</ArtistBio>
          </ArtistBioContainer>
        </ContentContainer>
        ))}
        </div>
      </MainContainer>
    </>
  )
}

export default About