import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { Link } from 'react-router-dom';

export const StyledButton = tw.button`
    flex
    text-4xl
    text-white
    items-center 
    cursor-pointer
    fixed
    right-4
    top-6
    z-50
`

export const StyledSVG = tw.svg`
    fixed 
    z-30
    flex
    items-center
    cursor-pointer
    right-4
    top-6
`

export const StyledDiv = tw.div`

`

export const StyledUL = tw.ul`

`

export const StyledListItem = tw.li`

`

export const StyledAnchor = tw.a`
    mt-20
    text-4xl
    font-semibold
    text-lisa-yellow/80
    drop-shadow-md
`

export const SocialMedia = tw.section`
    max-w-screen-xl
    w-full
`

export const SocialMediaWrap = tw.div`
    flex
    justify-center
    items-center
    max-w-screen-xl
    margin: 40px auto 0 auto;
    mt-6
    mr-auto
    mb-0
    ml-auto

    md:flex-col
`

export const SocialLogo = styled(Link)`
    text-black
    justify-self-start
    cursor-pointers
    text-2xl
    flex
    items-center
    mb-4
    font-bold
`

export const WebsiteRightsContainer = tw.div`
    mt-2
`

export const WebsiteRights = tw.small`
    text-white
    mb-4
    font-bold
    lg:text-xl
    font-acme
`
export const SocialIcons = tw.div`
    flex
    lg:justify-between
    justify-center
    items-center
    ml-4
    mr-4
`

export const SocialIconLink = tw.a`
    text-white
    px-4
    text-4xl
`