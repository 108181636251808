import React from 'react'
import Merch from '../../components/Artist/Merch/Merch'
import Navbar from '../../components/Artist/Navbar/Navbar'

const MerchAdminPage = () => {
  return (
    <>
      <Navbar />
      <Merch />
    </>
  )
}

export default MerchAdminPage