import React from 'react'
import Consultations from '../../components/Artist/Consultations/Consultations'
import Navbar from '../../components/Artist/Navbar/Navbar'

const ConsultationsPage = () => {
  return (
    <>
      <Navbar />
      <Consultations />
    </>
  )
}

export default ConsultationsPage