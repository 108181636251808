import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
`

export const TitleContainer = tw.div`
    container
    mx-auto
    px-4
    
`
export const Title = tw.h2`
    text-lg
    text-black
`
export const ItemsContainer = tw.div`
    justify-center
    align-center
    flex
    flex-wrap
`

export const ItemCard = tw.div`
    p-6
    rounded-xl
    my-2
    mx-2
    bg-white
    rounded-lg
    shadow
    dark:bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20
`

export const ItemPicture = tw.img`
    rounded-lg
    object-cover
    h-60
    w-60
`

export const ItemName = tw.p`
    text-lisa-green
    font-bold
    text-lg
    font-acme
`

export const ItemDesc = tw.p`
    text-white
    font-bold
    text-lg
    font-acme
`

export const ItemPrice = tw.p`
    text-lisa-yellow
    font-bold
    text-lg
    font-acme
`