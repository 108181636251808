import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { MainContainer, TitleContainer, Title, EventsContainer, EventCard, EventDetails, EventTitle, EventDates, EventLocation, EventPicture} from './MiniEventsElements';

const MiniEvents = () => {
  const [recentEvents, setRecentEvents] = useState([])

  const getRecentEvents = async () => {
      const response = await axios.get('events.json')
      setRecentEvents(response.data)
      console.log(response.data)
  }

  useEffect(() => {
      getRecentEvents();
  }, [])

  return (
    <>
        <MainContainer>
            <TitleContainer>
                <Title>Upcoming Events</Title>
            </TitleContainer>
            <EventsContainer>
            {recentEvents.map((recentEvents) => (
              <EventCard key={recentEvents.id}>
                  <a href={recentEvents.event_url}>
                    <EventPicture src={recentEvents.image} />
                  </a>
                  <EventTitle>{recentEvents.title}</EventTitle>
                  <EventDates>{recentEvents.start_date}</EventDates>
                  <EventDates>{recentEvents.end_date}</EventDates>
                  <EventLocation>{recentEvents.location}</EventLocation>
              </EventCard>
            ))}
            </EventsContainer>
        </MainContainer>
    </>
  )
}

export default MiniEvents