import React from 'react'
import { MainContainer, TitleContainer, Title } from './HeaderElements'
import sjheader from '../../assets/images/stephaniejohnsonnav.png'
import { StyledImg } from './HeaderElements'

const Header = () => {
  return (
    <>
      <MainContainer>
        <a href="/">
          <StyledImg src={sjheader} />
        </a>
      </MainContainer>
    </>
  )
}

export default Header