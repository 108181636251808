import React from 'react'
import ContactForm from '../components/Contact/Form';

const Contact = () => {
  return (
    <>
      <ContactForm />
    </>
  )
}

export default Contact