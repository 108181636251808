import React from 'react'
import FAQsComponent from '../components/FAQs/FAQsComponent'

const FAQs = () => {
  return (
    <>
      <FAQsComponent />
    </>
  )
}

export default FAQs