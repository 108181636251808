import styled from 'styled-components'
import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
    mx-4
    flex
    justify-center
`

export const StyledForm = tw.form`
    flex
    w-full
    lg:max-w-3xl
    max-w-md
    space-x-3
`

export const FormDiv = tw.div`
    w-full
    max-w-2xl
    px-5
    py-10
    m-auto
    bg-white
    rounded-lg
    shadow
    dark:bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20
`

export const StyledOuterDiv = tw.div`
    grid
    max-w-xl
    grid-cols-2
    gap-4
    m-auto
`

export const FormHeading = tw.div`
    mb-6
    text-3xl
    font-light
    text-center
    text-white
    font-acme
`

export const StyledInputDiv = tw.div`
    col-span-2
`

export const StyledDualInputDiv = tw.div`
    col-span-2 lg:col-span-1
`

export const RelativeDiv = tw.div`
    relative
`

export const StyledInput = tw.input`
    appearance-none
    block
    w-full
    bg-gray-200
    text-gray-700
    border
    border-gray-200
    rounded
    py-3
    px-4
    mb-3
    leading-tight
    focus:outline-none
    focus:bg-white
    focus:border-gray-500
`

export const StyledTextAreaLabel = tw.label`
    text-gray-700
`

export const StyledTextArea = tw.textarea`
    flex-1
    appearance-none
    border 
    border-gray-300 
    w-full 
    py-2 
    px-4
    h-72
    bg-white 
    text-gray-700 
    placeholder-gray-400 
    rounded-lg 
    text-base 
    focus:outline-none 
    focus:ring-2 
    focus:ring-purple-600 
    focus:border-transparent
`

export const StyledLabel = tw.label`
    block
    uppercase
    tracking-wide
    tex-700
    text-xs
    font-bold
    mb-2
    text-white
`

export const StyledButton = tw.button`
    focus:outline-none
    text-white
    bg-lisa-blue
    font-medium
    rounded-lg
    text-sm
    px-5 
    py-2.5
    mb-2
`