
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Masonry from 'react-masonry-css';
import { PostContainer, PostImage } from './MasonryElements'

const MasonryLayout = () => {
  const [images, setImages] = useState([]);
  const [posts, setPosts] = useState([]);

  const getImages = async () => {
        const response = await axios.get('galleryPosts.json')
        setImages(response.data)
    }
  
  useEffect(() => {
      getImages();
  }, [])

  const breakpointColumnsObj = {
    default: 4,
    3000: 6,
    2000: 5,
    1200: 3,
    1000: 2,
    500: 1,
  };

//   const params = {user_id: '2262137316', batch_size: '50'}
//   const headers = {
//     'x-rapidapi-host': 'instagram28.p.rapidapi.com',
//     'x-rapidapi-key': '100fe98584msh3cc3759f9aead09p18070ajsnfd70377b7ae1',
//     'Access-Control_Allow-Origin': '*'
//     }

//   const getPosts = async () => {
//     const response = await axios.get('https://instagram28.p.rapidapi.com/medias', { params, headers});
//     setPosts(response.data.data.user.edge_owner_to_timeline_media.edges)
//     console.log(posts)
// }

//     useEffect(() => {
//         getPosts();
//     }, [])


  return (
    <>
        <Masonry
          className="flex animate-slide-fwd"
          breakpointCols={breakpointColumnsObj}
        >
        {images.map((images) => (
        <PostContainer key={images.id}>
          <PostImage
            
            className="w-max"
            src={images.url}
          />
        </PostContainer>
        ))}
        </Masonry>
    </>
  )
}

export default MasonryLayout