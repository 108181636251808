import React from 'react'
import { InsideDiv, MainContainer, NavItemAnchor, StyledSpan, StyledUL } from './NavbarElements'
import FAQs from '../../../pages/FAQs';
import { FaCalendarAlt } from 'react-icons/fa'
import { FcHome, FcCalendar, FcSms, FcPlanner, FcFaq, FcRules, FcQuestions, FcContacts } from 'react-icons/fc'

const Navbar = () => {
  return (
    <>
      <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
   <span class="sr-only">Open sidebar</span>
   <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
   </svg>
</button>

      <MainContainer>
        <InsideDiv>
          <StyledUL>
            <li>
              <NavItemAnchor href="/artist/dashboard">
                <FcHome />
                <StyledSpan>Home</StyledSpan>
              </NavItemAnchor>
            </li>
            <li>
              <NavItemAnchor href="/artist/messages">
                <FcSms />
                <StyledSpan>Messages</StyledSpan>
              </NavItemAnchor>
            </li>
            <li>
              <NavItemAnchor href="/artist/calendar">
                <FcCalendar />
                <StyledSpan>Calendar</StyledSpan>  
              </NavItemAnchor>
            </li>
            <li>
              <NavItemAnchor href="/artist/consultations">
                <FcQuestions />
                <StyledSpan>Consultations</StyledSpan>
              </NavItemAnchor>
            </li>
            <li>
              <NavItemAnchor href="/artist/bookings">
                <FcPlanner />
                <StyledSpan>Bookings</StyledSpan>
              </NavItemAnchor>
            </li>
            <li>
              <NavItemAnchor href="/artist/merch">
                <StyledSpan>Merch</StyledSpan>
              </NavItemAnchor>
            </li>
            <li>
              <NavItemAnchor href="/artist/clients">
                <FcContacts />
                <StyledSpan>Clients</StyledSpan>
              </NavItemAnchor>
            </li>
            <li>
              <NavItemAnchor href="/artist/events">
                <StyledSpan>Events</StyledSpan>
              </NavItemAnchor>
            </li>
            <li>
              <NavItemAnchor href="/artist/faqs">
                <FcFaq />
                <StyledSpan>FAQs</StyledSpan>
              </NavItemAnchor>
            </li>
          </StyledUL>
        </InsideDiv>
      </MainContainer>
    </>
  )
}

export default Navbar