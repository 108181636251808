import React from 'react'
import Clients from '../../components/Artist/Clients/Clients'
import Navbar from '../../components/Artist/Navbar/Navbar'

const ClientsPage = () => {
  return (
    <>
      <Navbar />
      <Clients />
    </>
  )
}

export default ClientsPage