import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
    mx-4
`

export const TitleContainer = tw.div`
    container
    mx-auto
`

export const Title = tw.h2`
    text-2xl
    text-white
    font-acme
`
export const EventsContainer = tw.div`
    justify-center
    align-center
    flex
    flex-wrap
    overflow-auto
    whitespace-nowrap
`

export const EventCard = tw.div`
    p-4
    my-2
    mx-2
    bg-white
    rounded-lg
    shadow
    dark:bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20
`
export const EventPicture = tw.img`
    rounded-lg
    object-cover
    h-96
    w-96
`

export const EventTitle = tw.p`
    text-white
    font-bold
    text-lg
    font-acme
`

export const EventDates = tw.p`
    text-lisa-green
    font-bold
    text-lg
`

export const EventLocation = tw.p`
    text-lisa-yellow
    font-bold
    text-lg
    font-acme
`

export const EventDetails = tw.p`
    text-black
    font-acme
`