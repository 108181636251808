import styled from 'styled-components'
import tw from 'tailwind-styled-components';

export const MainContainer = tw.aside`
    fixed
    top-0
    left-0
    z-40
    w-64
    h-screen
    transition-transform
    -translate-x-full
    sm:translate-x-0
`
export const InsideDiv = tw.div`
    h-full
    px-3
    py-4
    overflow-y-auto
    bg-gray-50
    dark:bg-blue-800
`

export const StyledUL = tw.ul`
space-y-2
`

export const NavItemAnchor = tw.a`
    flex
    items-center
    p-2
    text-base
    font-normal
    text-gray-900
    jnrounded-lg
    dark:text-white
    hover:bg-gray-100
    dark:hover:bg-gray-700
`

export const StyledSVG = tw.svg`
    w-6
    h-6
    text-gray-500
    transition
    duration-75
    dark:text-gray-400
    group-hover:text-gray-900
    ddark:group-hover:text-white
`

export const StyledSpan = tw.span`

`