import styled from 'styled-components';
import { Link } from 'react-router-dom';
import tw from 'tailwind-styled-components';

export const FooterContainer = tw.footer`
    
`

export const FooterWrap = tw.div`
    py-6
    px-24
    flex
    flex-col
    justify-center
    items-center
    max-w-screen-xl
    m-auto
`

export const SocialMedia = tw.section`
    max-w-screen-xl
    w-full
`

export const SocialMediaWrap = tw.div`
    flex
    justify-center
    items-center
    max-w-screen-xl
    margin: 40px auto 0 auto;
    mt-6
    mr-auto
    mb-0
    ml-auto

    md:flex-col
`

export const SocialLogo = styled(Link)`
    text-black
    justify-self-start
    cursor-pointers
    text-2xl
    flex
    items-center
    mb-4
    font-bold
`

export const WebsiteRightsContainer = tw.div`
    mt-2
`

export const WebsiteRights = tw.small`
    text-white
    mb-4
    font-bold
    lg:text-xl
    font-acme
`
export const SocialIcons = tw.div`
    flex
    lg:justify-between
    justify-center
    items-center
    ml-4
    mr-4
`

export const SocialIconLink = tw.a`
    text-white
    px-4
    text-4xl
`