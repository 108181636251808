import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import { MainContainer, StyledForm, FormDiv, FormHeading, StyledInput, RelativeDiv, StyledOuterDiv, StyledDualInputDiv, StyledLabel, StyledInputDiv, StyledTextAreaLabel, StyledTextArea, StyledButton } from './FormElements';
import emailjs from 'emailjs-com';
import axios from 'axios';

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const sendContactForm = async (e) => {
    let formField = new FormData()
    formField.append('service_id', 'service_4gtm88t');
    formField.append('template_id', 'template_3be0jdm');
    formField.append('user_id', 'r8DSkThdCzsJPjpTT');
    formField.append('from_email', email)
    formField.append('from_name', name)
    formField.append('to_name', 'Stephanie Johnson')
    formField.append('message', message)

    await axios({
        method: 'post',
        url:'https://api.emailjs.com/api/v1.0/email/send-form',
        data: formField
      }).then(response=>{
        alert("Thank you for your submission, we will get back to you ASAP!")
      }).catch(error=>console.error(error))
};

const handleSubmit = (e) => {
  e.preventDefault();
  
}

  return (
    <MainContainer>
        <StyledForm onSubmit={handleSubmit}>
          <FormDiv>
            <FormHeading>Contact Form</FormHeading>
            <StyledOuterDiv>
            <StyledLabel>Please submit a contact form if you have any questions, comments, or concerns you would like me to know about!
            </StyledLabel>
            <StyledLabel>If you would like more info about a tattoo you'd like to schedule, please submit a consultation instead.</StyledLabel>
                <StyledInputDiv>
                <StyledLabel>Name</StyledLabel>
                <StyledInput type="text" id="name" defaultValue={name}  onChange={(e) => setName(e.target.value)} />
              </StyledInputDiv>
              <StyledInputDiv>
              <StyledLabel>Email</StyledLabel>
                <StyledInput type="email" id={email} placeholder="whatisyouremail@email.com" onChange={(e) => setEmail(e.target.value)} />
              </StyledInputDiv>
              <StyledInputDiv >
                  <StyledLabel>Message</StyledLabel>
                  <StyledTextArea type="text" id={message} defaultValue="" placeholder="What can I help you with?"  onChange={(e) => setMessage(e.target.value)} ></StyledTextArea>
              </StyledInputDiv>
              <StyledButton type="submit" onClick={(e) => sendContactForm(e)}>Submit</StyledButton>
            </StyledOuterDiv>
          </FormDiv>
        </StyledForm>
      </MainContainer>
  )
}

export default ContactForm