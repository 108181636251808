import React from 'react'
import Navbar from '../../components/Artist/Navbar/Navbar'

const DashboardPage = () => {
  return (
    <>
      <Navbar />
    </>
  )
}

export default DashboardPage