import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
    mx-4
    rounded-xl
`

export const ContentContainer = tw.div`
    mx-auto
    px-4
    flex
    flex-wrap
    
    dark:bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20
    item-center
`

export const ArtistPictureContainer = tw.div`

`

export const ArtistPicture = tw.img`
    rounded-lg
    object-cover
    
`

export const ArtistBioContainer = tw.div`
    lg:flex-1
    p-4
    rounded-xl
`

export const ArtistBio = tw.p`
    text-lg
    lg:line-clamp-12
    line-clamp-6
    text-white
    font-bold
    leading-10
    font-acme
`

export const StyledButton = tw.button`
    focus:outline-none
    text-white
    bg-lisa-blue
    font-medium
    rounded-lg
    text-sm
    px-5 
    py-2.5
    mb-2
`