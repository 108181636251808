import React, {useState} from 'react';
import './App.css';
import Home from './pages/Home';
import Gallery from './pages/Gallery';
import About from './pages/About';
import Contact from './pages/Contact';
import MerchPage from './pages/MerchPage';
import Events from './pages/Events';
import Consultation from './pages/Consultation';
import FAQs from './pages/FAQs';
import Header from './components/Header/Header';

import { 
  BrowserRouter,
  Routes, 
  Route 
} from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/Artist/DashboardPage';
import CalendarPage from './pages/Artist/CalendarPage';
import BookingsPage from './pages/Artist/BookingsPage';
import ClientsPage from './pages/Artist/ClientsPage';
import ConsultationsPage from './pages/Artist/ConsultationsPage';
import MessagesPage from './pages/Artist/MessagesPage';
import ProfilePage from './pages/Artist/ProfilePage';
import MerchAdminPage from './pages/Artist/MerchPage';
import ArtistLoginPage from './pages/Artist/ArtistLoginPage';

function App() {

  return (
    <>
      <BrowserRouter>
        <Header />
        <div className="flex flex-col items-center justify-center min-h-0 py-2">
            <Navbar />
        </div>
          <Routes>
            {/* General Pages, public facing no auth needed. */}
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/merch" element={<MerchPage />} />
            <Route path="/events" element={<Events />} />
            <Route path="/consultation" element={<Consultation />} />
            <Route path="/faqs" element={<FAQs />} />

            {/* Pages for authenticated users/clients/customers */}
            <Route path="/login" element={<LoginPage />} />


            {/* Pages for authenticated admin/artist users */}
            <Route path="/artist/login" element={<ArtistLoginPage />} />

            <Route path="/artist/dashboard" element={<DashboardPage />} />
            <Route path="/artist/bookings" element={<BookingsPage />} />
            <Route path="/artist/calendar" element={<CalendarPage />} />
            <Route path="/artist/clients" element={<ClientsPage />} />
            <Route path="/artist/consultations" element={<ConsultationsPage />} />
            <Route path="/artist/merch" element={<MerchAdminPage />} />
            <Route path="/artist/messages" element={<MessagesPage />} />
            <Route path="/artist/profile" element={<ProfilePage />} />
            
          </Routes>
          <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
