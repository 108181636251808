import React from 'react'

const Login = () => {
    const clientId = '484007209982-4vnk5tdr63jrpr11m07u80f86986gudo.apps.googleusercontent.com';

    const onSuccess = async (res) => {
        
    }

  return (
    <div>Login</div>
  )
}

export default Login