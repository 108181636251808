import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { MainContainer, TitleContainer, Title, GalleryContainer, GalleryCard, StyledImage, GalleryPicture } from './MiniGalleryElements'
import { baseUrl, fetchApi } from "../../utils/fetchInstagramApi"

const MiniGallery = () => {
  const [recentImages, setRecentImages] = useState([])
  const [recentPosts, setRecentPosts] = useState([])

  const getRecentImages = async () => {
      const response = await axios.get('recentPosts.json')
      setRecentImages(response.data)
  }

  useEffect(() => {
      getRecentImages();
  }, [])

//   const params = {user_id: '2262137316', batch_size: '8'}
//   const headers = {
//     'x-rapidapi-host': 'instagram28.p.rapidapi.com',
//     'x-rapidapi-key': '100fe98584msh3cc3759f9aead09p18070ajsnfd70377b7ae1'
//     }

//   const getRecentPosts = async () => {
//     const response = await axios.get('https://instagram28.p.rapidapi.com/medias', { params, headers});
//     setRecentPosts(response.data.data.user.edge_owner_to_timeline_media.edges)
//     console.log(response.data.data.user.edge_owner_to_timeline_media.edge)
// }

//     useEffect(() => {
//         getRecentPosts();
//     }, [])

  return (
    <>
        <MainContainer>
            <TitleContainer>
                <Title>Recent Work</Title>
            </TitleContainer>
            <GalleryContainer>
            {recentImages.map((recentImages) => (
                <GalleryCard key={recentImages.id}>
                    <GalleryPicture src={recentImages.url}/>
                </GalleryCard>
                ))}
            </GalleryContainer>
        </MainContainer>
    </>
  )
}

export default MiniGallery