import styled from 'styled-components';
import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`

`

export const ContentContainer = tw.div`
container
mx-auto
px-4
py-4
grid-cols-2
flex
flex-wrap
dark:bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20
`

export const ArtistPictureContainer = tw.div`
    lg:flex-1
    mr-6
    w-full
`

export const ArtistPicture = tw.img`
    rounded-lg
    object-cover
`

export const ArtistBioContainer = tw.div`
    lg:flex-1
`

export const ArtistBio = tw.p`
text-lg
text-white
font-bold
leading-10
`